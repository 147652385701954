<template>
  <v-dialog
      transition="dialog-bottom-transition"
      v-model="dialog"
      max-width="400"  >
    <template #activator="{ on, attrs }">
      <v-chip
          :color="color"
          text-color="white"
          class="ma-2"
          small
          v-on="on"
          v-bind="attrs"
      >
        <slot></slot>
      </v-chip>
    </template>
    <template #default>
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >请选择颜色
        </v-toolbar>
        <v-card-text>
          <v-color-picker
              show-swatches
              hide-canvas
              hide-inputs
              hide-sliders
              hide-mode-switch
              swatches-max-height="400px"
              v-model="selectedColor"
          ></v-color-picker>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="selected()"
          >确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "buttonNewServiceOrder",
  model: {
    prop: 'color',
    event: 'selected'
  },
  props: {
    color: String
  },
  data(){
    return {
      dialog:false,
      selectedColor:'success'
    }
  },
  methods:{
    selected(){
      this.$emit('selected',this.selectedColor)
      this.dialog = false
    }
  }

}
</script>

<style scoped>

</style>