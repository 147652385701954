<template>
  <div>
    <div class="button-container mb-4">
      <v-btn @click="save()" color="primary">保存</v-btn>
      <v-btn @click="showEdit()">添加</v-btn>
    </div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left"> 编号</th>
          <th class="text-left"> 名称</th>
          <th class="text-left"> 动作</th>
          <th class="text-left"> 操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in selectedProject.status" :key="item.name">
          <td> {{ item.code }}</td>
          <td>
            <colorSelector v-model="item.color">{{ item.name }}</colorSelector>
          </td>
          <td>
            <div class="button-container">
              <v-checkbox label="修改" v-model="item.action.editable"></v-checkbox>
              <v-checkbox label="删除" v-model="item.action.deletable"></v-checkbox>
              <v-checkbox label="取消" v-model="item.action.cancellable"></v-checkbox>
              <v-checkbox label="费用" v-model="item.action.fee"></v-checkbox>
            </div>
          </td>
          <td>
            <div class="button-container">
              <v-btn @click="showEdit(item)">修改</v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div>
      <v-dialog v-model="editDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            状态
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="editStatus.name" label="状态名称" :error-messages="error"></v-text-field>
            <v-input label="状态颜色">
              <colorSelector v-model="editStatus.color">颜色</colorSelector>
            </v-input>
            <v-row>
              <v-col>
                <v-checkbox label="修改"
                            v-model="editStatus.action.editable"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox label="删除"
                            v-model="editStatus.action.deletable"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox label="取消"
                            v-model="editStatus.action.cancellable"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-checkbox label="费用"
                            v-model="editStatus.action.fee"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="editStatue()"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import colorSelector from "@/views/service/serviceSetting/colorSelector";
import db from "@/plugins/db";

const db_settings = db.collection('projectSettings')


export default {
  name: "projectStatusSetting",
  components: {colorSelector},
  props: ['project'],
  data() {
    return {
      selectedProject: {},
      editStatus: {
        action: {}
      },
      sss: '',
      editDialog: false,
      error: '',
      editModel: false,
    }
  },
  methods: {
    showEdit(status = {action: {}}) {
      if (!status.code) {
        status.code = Math.max(...this.selectedProject.status.map(s => s.code)) + 1
        this.editModel = false
      } else {
        this.editModel = true
      }
      this.editStatus = status
      this.error = ''
      this.editDialog = true
    },
    editStatue() {
      if (this.editStatus.name?.length >= 2 && this.editStatus.name?.length < 10) {
        if (!this.editModel) {
          this.selectedProject.status.push(this.editStatus)
        }
        this.editDialog = false
      }
      this.error = '请输入2-10位状态名称'
    },
    save() {
      if (this.selectedProject._id) {
        db_settings.doc(this.selectedProject._id)
            .update({
              status: this.selectedProject.status
            })
            .then(s => {
              console.log(s)
            })
      } else {
        db_settings.add(this.selectedProject).then(() => {
          this.$emit('projectSaved')
        })
      }
    },
  },
  watch: {
    'project'(v) {
      console.log('')
      this.selectedProject = v
    }
  }

}
</script>

<style scoped>
</style>