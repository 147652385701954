<template>
  <div class="container">
    <div class="project-nav">
      <v-card min-height="400"
              class="mx-auto"
      >
        <v-card-title class="white--text primary darken-1">项目
          <v-spacer></v-spacer>
          <addProjectButton @submit="onCreated">
          </addProjectButton>
        </v-card-title>
        <v-navigation-drawer permanent>

          <v-list dense nav>
            <v-list-item-group v-model="selected" color="primary" @change="selectProject">
              <v-list-item
                  v-for="project in projects"
                  :key="project.name"
              >
                <v-list-item-icon>
                  <v-icon>{{ project.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ project.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

          </v-list>
        </v-navigation-drawer>
      </v-card>
    </div>
    <div class="project-container">
      <v-tabs v-model="tab">
        <v-tab>状态</v-tab>
        <v-tab>other</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="pa-4 ma-4">
        <v-tab-item>
          <projectStatusSetting @projectSaved="getProjects()" :project="selectedProject"></projectStatusSetting>
        </v-tab-item>
        <v-tab-item>
          建设中
        </v-tab-item>
        <v-tab-item>
          tab3
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import db from "@/plugins/db";

const db_settings = db.collection('projectSettings')
import projectStatusSetting from "@/views/service/serviceSetting/projectStatusSetting";
import addProjectButton from "@/views/service/serviceSetting/addProjectButton";

export default {
  components: {projectStatusSetting, addProjectButton},
  data() {
    return {
      selectedProject: {},
      tab: '1',
      selected: 0,
      projects: [],
    }
  },
  created() {
    this.getProjects().then(this.selectProject)
  },
  methods: {
    selectProject(v = 0) {
      console.log('projectChange', v)
      this.selectedProject = this.projects[v]
    },
    onCreated(v) {
      this.projects.push(v)
    },
    getProjects() {
      return db_settings.get()
          .then(s => {
            console.log('getProjects: ', s)
            this.projects = s.data
          })
    },
    init() {
      // db_settings.add({serviceOrderDefaultStatus})
      //     .then(s => {
      //       this.settings._id = s._id
      //     })
    }
  }
}
</script>

<style scoped>

.project-nav {
  width: 200px;
}

.project-container {
  flex: 1;
  padding: 8px;

}

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
</style>