<template>
  <v-dialog
      v-model="dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          color="white"
          class="text--primary"
          fab
          small
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        新增项目
      </v-card-title>

      <v-card-text>
        <v-text-field label="项目名称" v-model="project.name" :error-messages="error"></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="confirm()"
        >
          确认
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          取消
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "addProjectButton",
  data() {
    return {
      dialog: false,
      error:'',
      project:{
        name:''
      },
    }
  },
  methods:{
    reset(){
      this.project = {
        name: '',
        icon: '',
        desc: '',
        status: [
          {
            code: 1,
            name: '新建',
            color: '',
            action:{}
          }
        ]
      }
      this.error = ''
    },
    confirm(){
      console.log(this.project.name)
      if (this.project.name.length>=4 && this.project.name.length<10){
        this.$emit('submit',this.project)
        this.dialog = false
      }else{
        this.error = '12313123'
      }

    }
  },
  watch:{
    dialog(v){
      if (v){
        this.reset()
      }
    }
  }
}
</script>

<style scoped>

</style>